.App {
  text-align: left;
  margin: 10;
  font-family: "SFMono-Regular", "Source Code Pro", "Consolas", "Menlo", "Courier", monospace;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  line-height: 1.8;
}

.main {
  max-width: 700px;
  margin: 0 auto 50px;
}

.main .p {
  margin-bottom: 15px;
}
