body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c2533;
  color: #fff;
}

a:link {
    color: #fff;
}

a:visited {
  color: #fff;
}

@media screen and (prefers-color-scheme: light) {
  body {
    background-color: white;
    color: black;
  }
  a:link {
    color: black;
  }
  a:visited {
    color: black;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
